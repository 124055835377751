import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/opt/build/repo/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import slideovers_LDumGYo2KH from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/opt/build/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_w2VlvAEcpa from "/opt/build/repo/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_6oy0ulSF66 from "/opt/build/repo/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/opt/build/repo/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import analytics_ABBiEqcBsv from "/opt/build/repo/plugins/analytics.js";
import appendUTMParameters_client_kzctSI6AEp from "/opt/build/repo/plugins/appendUTMParameters.client.ts";
import disableDarkMode_client_HieGFtTCWU from "/opt/build/repo/plugins/disableDarkMode.client.ts";
import lottie_client_funiu1oBjt from "/opt/build/repo/plugins/lottie.client.js";
import recaptcha_client_ePVGy12Odm from "/opt/build/repo/plugins/recaptcha.client.js";
import replaceEmojis_client_kVOQJ9V8Qd from "/opt/build/repo/plugins/replaceEmojis.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  plugin_w2VlvAEcpa,
  plugin_6oy0ulSF66,
  plugin_KlVwwuJRCL,
  analytics_ABBiEqcBsv,
  appendUTMParameters_client_kzctSI6AEp,
  disableDarkMode_client_HieGFtTCWU,
  lottie_client_funiu1oBjt,
  recaptcha_client_ePVGy12Odm,
  replaceEmojis_client_kVOQJ9V8Qd
]